module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"37 Computer Gallery","short_name":"37CG","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"/Users/manish-s/Projects/Code/COMPUTER_GALLERY/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a6903ebf512e61ecdda6270b7602d26b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
